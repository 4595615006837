<template>
  <div></div>
</template>
<script>
import { auth } from '../../sharedPlugin';

export default {
  methods: {
    ...auth.mapMethods(['logged']),
    async validate() {
      const accessToken = this.$route.params.token;
      await this.logged(accessToken);
      if (this.$router.currentRoute.path !== '/') {
        this.$router.push('/');
      }
    }
  },
  mounted() {
    this.validate();
  }
};
</script>
